import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from 'gatsby';
import buildLogo from '../../images/build-lite-logo.svg';
import clutch from '../../images/clutchco.svg';
import facebook from '../../images/facebook.svg';
import instagram from '../../images/instagram.svg';
import linkedin from '../../images/LinkedIn.svg';
import './Footer.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Container
      className="footer-container position-relative justify-content-center d-flex align-items-center pt-1"
      fluid
    >
      <>
        <img src={buildLogo} alt="Build" className="build-logo" />
      </>
      <div className="footer-text-container d-flex flex-column justify-content-between">
        <div className="footer-nav-links justify-content-between d-flex fw-bold pt-5">
          <Nav.Link as="div">
            <Link className="text-white text-decoration-none" to="/">Home</Link>
          </Nav.Link>
          <Nav.Link as="div">
            <Link className="text-white text-decoration-none" to="/about-us">About Us</Link>
          </Nav.Link>
          <Nav.Link as="div">
            <Link className="text-white text-decoration-none" to="/careers">Careers</Link>
          </Nav.Link>
          <Nav.Link as="div">
            <Link className="text-white text-decoration-none" to="/contact-us">Contact Us</Link>
          </Nav.Link>
        </div>
        <div className="social-network">
          <a
            className="social-media-icon"
            target="_blank"
            href="https://clutch.co/profile/build-1"
            rel="noreferrer"
          >
            <img src={clutch} alt="Clutch" />
          </a>
          <a
            className="social-media-icon"
            target="_blank"
            href="https://www.linkedin.com/company/buildto/mycompany/"
            rel="noreferrer"
          >
            <img src={linkedin} alt="Linkedin" />
          </a>
          <a
            className="social-media-icon"
            target="_blank"
            href="https://www.instagram.com/build.tech.global/"
            rel="noreferrer"
          >
            <img src={instagram} alt="Instagram" />
          </a>
          <a
            className="social-media-icon"
            target="_blank"
            href="https://www.facebook.com/buildwithbuild"
            rel="noreferrer"
          >
            <img src={facebook} alt="Facebook" />
          </a>
        </div>
        <div className="all-rights-text d-flex justify-content-center">
          <p>© {currentYear} Build. All rights reserved</p>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
