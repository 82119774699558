import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'gatsby';
import breakpoints from '../../constants/breakpoints';
import './Header.scss';
import buildLogo from '../../images/build-lite-logo.svg';
import buildBLogo from '../../images/build_b.svg';
import useWindowSize from '../../hooks/useWindowSize';

const Header = ({ activePath }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [logo, setLogo] = useState(buildLogo);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width < breakpoints.md) {
      setIsMobile(true);
      setLogo(buildBLogo);
    } else {
      setIsMobile(false);
      setIsCollapsed(true);
      setLogo(buildLogo);
    }
  }, [size.width]);

  useEffect(() => {
    document.body.style.overflow = 'visible';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const setToggle = () => {
    setIsCollapsed((prev) => !prev);
    if (!isCollapsed) {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };
  return (
    <header className="header">
      <Navbar
        className="justify-content-between w-100"
        variant="dark"
        expand="md"
        onToggle={setToggle}
      >
        <div className="top-header">
          <Navbar.Brand href="/" className="logo-section">
            <img src={logo} alt="Build" className="build-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="ps" />
        </div>
        <Navbar.Collapse>
          <div className="nav-links align-items-center">
            <Nav activeKey={activePath}>
              <Nav.Link eventKey="/" as="div" className="py-2 px-1 px-md-4">
                <Link activeClassName="fw-bold" className="text-decoration-none" to="/">
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link
                as="div"
                eventKey="/about-us"
                className="btn-nav py-2 px-1 px-md-4"
              >
                <Link activeClassName="fw-bold" className="text-decoration-none" to="/about-us">
                  About Us
                </Link>
              </Nav.Link>
              <Nav.Link
                as="div"
                className="btn-nav py-2 px-1 px-md-4"
                eventKey="/careers"
              >
                <Link activeClassName="fw-bold" className="text-decoration-none" to="/careers">
                  Careers
                </Link>
              </Nav.Link>
              <Nav.Link
                as="div"
                eventKey="/contact-us"
                className={`${
                  isMobile ? 'btn-nav px-md-4' : 'btn-contact'
                } py-2 px-1 mb-1`}
              >
                <Link to="/contact-us" activeClassName="fw-bold" className={`${!isMobile ? 'p-2 px-2' : 'py-2'} m-0 text-decoration-none`}>
                  Contact Us
                </Link>
              </Nav.Link>
            </Nav>
          </div>
          <Navbar.Brand href="/" className="logo-section">
            <img src={buildLogo} alt="Build" className="bottom-build-logo" />
          </Navbar.Brand>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  activePath: PropTypes.string.isRequired,
};

export default Header;
